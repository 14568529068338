.label {
    color: #241c15;
    font-weight: 500;
    display: flex;
    max-width: 635px;
    padding-bottom: var(--spacing-3);
    justify-content: space-between;
}

.secondary {
    color: #403b3b;
    font-size: 1.4rem;
    font-weight: 400;
    text-align: right;
}
