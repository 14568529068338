.rangeRadioContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    margin-bottom: calc(var(--base-unit) * 3);

    @media only screen and (--mq-max-width-tablet) {
        flex-wrap: wrap;
        justify-content: center;
    }
}

.surveyRadio {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0,0,0,0);
    border: 0;
    &:focus + .surveyRadioLabel {
        outline-style: solid;
    }
}

.surveyRadioLabel {
    flex-grow: 1;
    min-width: calc(var(--base-unit) * 9) !important;
    text-align: center;
    padding: 4px;
    border: solid 1px var(--border-light-color);
    border-radius: calc(var(--base-unit) * 0.75);
    margin-left: calc(var(--base-unit) * 1.5);
    margin-right: calc(var(--base-unit) * 1.5);
    &:hover:not(.disabled) {
        background-color: var(--brand-kale-light);
    }

    @media only screen and (--mq-max-width-tablet) {
        flex-grow: 0;
        margin-bottom: calc(var(--base-unit) * 1.5);
        max-width: calc(var(--base-unit) * 24);
    }
}

.smiley {
    font-size: 21px;
}

.star,
.heart {
    font-size: 13px;
}

.surveyRadio:checked + label {
    border-color: var(--border-interactive-color);
    background: linear-gradient(0deg, rgba(0, 124, 137, 0.2), rgba(0, 124, 137, 0.2)), #ffffff;
}

.rangeLabelContainer {
    display: none;
    @media only screen and (--mq-min-width-tablet) {
        display: flex;
        justify-content: space-between;
        font-size: var(--font-size-small);
    }
}

.rangeLabel {
    width: auto !important;
    font-size: 14px;
    &:first-child {
        margin-left: calc(var(--base-unit) * 3);
    }
    &:last-child {
        margin-right: calc(var(--base-unit) * 4.5);
    }
}

.mobileRangeLabelContainer {
    display: flex;
    justify-content: space-between;
    font-size: var(--font-size-small);
    margin-bottom: calc(var(--base-unit) * 3);
    @media only screen and (--mq-min-width-tablet) {
        display: none;
    }
}
