.submitContainer {
    display: flex;
    justify-content: center;

    button {
        text-transform: none;
    }
}

.formContainer {
    padding: var(--spacing-7);
}
