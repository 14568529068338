.uploadVideo {
    position: relative;
    padding-bottom: 56.25%; /* ratio calc(16/9) */

    iframe,
    img {
        border: none;
        position: absolute;
        width: 100%;
        height: 100%;
    }
    iframe[seamless] {
        display: block;
    }
}

.thumbnail {
    width: 100%;
    height: 100%;
}

