.container {
    display: inline-block;

    .checkbox {
        visibility: hidden;
        margin-right: var(--spacing-3);
        position: absolute;
    }

    .labelText {
        position: relative;
        cursor: pointer;
        color: #241c15;
        font-weight: normal;
        padding-left: 36px;
        display: inline-block;

        &.withDescription {
            font-weight: 500;
        }

        &::before,
        &::after {
            content: '';
            display: inline-block;
            position: absolute;
            left: 0;
        }

        /* Checkbox outline */
        &::before {
            height: var(--spacing-6);
            width: var(--spacing-6);
            top: 0;
            border: 1px solid rgba(36, 28, 21, 0.15);
            border-radius: var(--legacy-border-input-radius);
            background-color: #ffffff;
            transition: background-color 0.2s linear;
        }

        /* Check mark */
        &::after {
            opacity: 0;
            transition: opacity 0.2s linear;
            height: 4px;
            width: 6px;
            background: transparent;
            border-left: 2px solid #ffffff;
            border-bottom: 2px solid #ffffff;
            transform: rotate(-45deg);

            top: 6px;
            left: 7px;
        }
    }

    /* Checkbox states */
    .checkbox:focus + .labelText::before {
        outline: 1px solid #004e56;
    }

    .checkbox:checked + .labelText::before {
        background-color: #007c89;
        border: 1px solid #004e56;
    }

    .checkbox:checked + .labelText::after {
        opacity: 1;
    }
}

.description {
    color: #241c15;
    padding-left: 36px;
}
