
.questionText {
    font-weight: var(--font-weight-bold);
    margin-bottom: var(--spacing-3);
}

.questionTextHtmlContainer {
    display: flex;
    & > p {
        display: inline-block;
        margin-bottom: 0;
    }


    /*  is required */
    & > span {
        /*  base spacing on font size */
        padding-left: 0.3em;
    }

    &.questionText {
        margin-bottom: 0;
        font-weight: initial;
    }
}

