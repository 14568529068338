.surveyIntro {
    border-bottom: solid 1px #f2f2f2;
    width: 100%;
    padding: var(--spacing-7) var(--spacing-7) var(--spacing-5) var(--spacing-7);
}

.surveyDescription {
    text-align: center;
}

.logoContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: var(--spacing-6) 0;
}

.logo {
    max-width: 200px;
}
