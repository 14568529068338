.labelText {
    color: #241c15;
    font-weight: 500;
    display: block;
    max-width: 635px;
    padding-bottom: var(--spacing-3);
}

.inputWrapper {
    display: flex;
    align-items: center;
    vertical-align: middle;
    border-radius: var(--legacy-border-input-radius);
    min-width: 50px;
    max-width: 635px;
    width: 100%;
    min-height: 36px;
    background-color: #ffffff;
}

.input {
    display: inline-block;
    padding: 0 6px;
    vertical-align: middle;
    min-width: 50px;
    max-width: 635px;
    width: 100%;
    min-height: 36px;
    background-color: #ffffff;
    border: 1px solid var(--legacy-border-input-color);
    border-radius: var(--legacy-border-input-radius);
    transition: all 0.2s ease-in-out 0s;
    font-family: var(--typeface-base, "Graphik Web", "Helvetica Neue", Helvetica, Arial, Verdana, sans-serif);
    font-size: 15px;
    line-height: 18px;
    font-weight: normal;
    margin-bottom: 0;

    /* `transition: all` breaks the focusability of the input when its used
       inside of a Modal. Nobody knows why, but being more explicit about it
       seems to work. :)
    */
    transition: border-color 200ms ease-in-out, box-shadow 200ms ease-in-out, opacity 200ms ease-in-out;

    &:focus {
        outline: none;
        border: 1px solid #007c89;
        box-shadow: inset 0 0 0 1px #007c89;
    }

    &.invalid {
        border-color: #a73205;
        box-shadow: inset 0 0 0 1px #a73205;
    }

    &[disabled] {
        filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale"); /* Firefox 10+, Firefox on Android */
        -webkit-filter: grayscale(100%);
        opacity: 0.5;
        cursor: not-allowed;
        background-color: #efeeea;
        color: #241c15;
    }
}

.textarea {
    font-family: var(--typeface-base, "Graphik Web", "Helvetica Neue", Helvetica, Arial, Verdana, sans-serif);
    font-size: 15px;
    line-height: 18px;
    font-weight: normal;
    height: auto;

    /*IE 10 and IE 11 scroll overlay fix. Without this the scroll bar will overlap the right-side of the text area. */
    -ms-overflow-style: scrollbar;
    display: inline-block;
    padding: 8px;
    margin-bottom: var(--spacing-6);
    vertical-align: middle;
    min-width: 50px;
    max-width: 635px;
    width: 100%;
    min-height: 36px;
    background-color: #ffffff;
    border: 1px solid var(--legacy-border-input-color);
    border-radius: var(--legacy-border-input-radius);

    transition: all 0.2s ease-in-out 0s;

    &:focus {
        outline: none;
        border: 1px solid #007c89;
        box-shadow: inset 0 0 0 1px #007c89;
    }

    &.invalid {
        border-color: #a73205;
        box-shadow: inset 0 0 0 1px #a73205;
    }

    &[disabled] {
        filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale"); /* Firefox 10+, Firefox on Android */
        -webkit-filter: grayscale(100%);
        opacity: 0.5;
        cursor: not-allowed;
    }
}

.invalid {
    border-color: #a73205;
    box-shadow: inset 0 0 0 1px #a73205;
}

.inputSuffix {
    margin-left: 6px;
}
