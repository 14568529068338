.sectionsList {
    list-style: none;
    display: block;
    margin-bottom: 0;
    padding-left: 0;

    &::before {
        content: none;
    }

    & > li {
        display: block;
    }
}
