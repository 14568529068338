.radioInput {
    margin-right: 12px;
    font-size: 16px;
    vertical-align: -5%;
}

.label {
    margin-bottom: 6px;
    display: block;
    padding: 6px;
    margin-left: calc(var(--base-unit) * 7.5);
    text-indent: calc(var(--base-unit) * -7.5);
    &:hover {
        background-color: var(--brand-kale-light);
    }
}

.labelText {
    font-size: 16px;
}
