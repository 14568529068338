.fullHeightContainer {
    position: relative;
    min-height: 100vh;
    display: block;
}

.outerContainer {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: var(--spacing-4);
    background-color: #f2f2f2;
    flex-grow: 1;
    margin: 0 auto;
}

.innerContainer {
    background-color: var(--bg-lvl-1-color);
    max-width: var(--hosted-survey-content-width);
    border-radius: var(--survey-border-radius);
    width: 100%;
}

.footerContainer {
    max-height: var(--hosted-survey-footer-height);
}

.footerBar {
    max-height: var(--hosted-survey-footer-height);
    background: rgba(255, 255, 255, 0.6);
    padding: var(--spacing-3) 36px;
}

.footerBarContent {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: auto;
    max-width: var(--hosted-survey-large-content-width);
    height: var(--hosted-survey-footer-content-height);
}

.privacyLink {
    color: var(--text-primary-color);
    font-size: var(--font-size-small);
    &:hover {
        color: var(--text-primary-color);
        text-decoration: underline;
    }
}

.createdWithLink,
.createdWithLink:hover {
    font-size: var(--survey-tiny-fs);
    color: var(--text-primary-color);
    text-decoration: none;
    vertical-align: middle;
}

.createdWithLogo {
    width: calc(var(--base-unit) * 24.9);
    margin-left: calc(var(--base-unit) * 1.5);
    vertical-align: middle;
}

.logo {
    width: 100px;
}
