.textContainer {
    ul,
    ol {
        list-style: auto;
        padding-left: 1.2em;
    }

    ul {
        list-style: disc;
    }
}
