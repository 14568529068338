.questionContainer {
    margin-bottom: var(--spacing-7);
    word-break: break-word;
}

.questionText {
    font-weight: var(--font-weight-bold);
    margin-bottom: var(--spacing-3);
}

.inputWrapper {
    position: relative;

    /* StackLayout equivalent */
    display: flex;
    flex-direction: column;
    gap: var(--spacing-6);

    label {
        width: 100%;
        display: block;
        cursor: pointer;
    }
}

.questionLabel {
    padding-bottom: var(--spacing-3);
}

.selectQuestion {
    display: flex;
    align-items: center;
    vertical-align: middle;
    min-width: 50px;
    max-width: 635px;
    width: 100%;
    min-height: calc(var(--base-unit) * 9);
    background-color: var(--bg-lvl-1-color);
    font-family: var(--typeface-base);
    font-size: 15px;
    line-height: 18px;
    font-weight: normal;
    margin-bottom: 0;
    border-color: var(--border-color);
    border-radius: var(--border-radius-lv2);
    &:not([disabled]):focus {
        outline: 2px solid var(--border-interactive-color);
        outline-offset: 1px;
        border: 1px solid var(--border-input-color-hover);
        box-shadow: inset 0 -2px 0 transparent;
    }
    padding: 10px 32px 10px 16px;
}
