@import "Survey/shared/Form/SharedFormFieldList";

.videoIntroduction {
    padding: 30px;
}

.imageSection {
    /* StackLayout equivalent */
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 6px;
}

.imageWrapper {
    padding-bottom: calc(var(--base-unit) * 6);
    align-self: center;
}

.textWrapper {
    margin-bottom: calc(var(--base-unit) * 9);
}
