.container {
    display: inline-block;
    font-weight: var(--font-weight-bold);
    font-family: var(--typeface-base);
    text-align: center;
    vertical-align: middle;
    text-transform: capitalize;
    user-select: none;
    white-space: nowrap;
    overflow: hidden;
    padding: 0 18px;
    margin-right: 18px;
    border: 1px solid transparent;
    border-radius: var(--border-radius-lv5);
    display: inline-block;
    transition:
        border 0.2s ease-in-out 0s,
        box-shadow 0.2s ease-in-out 0s,
        background-color 0.2s ease-in-out 0s;
    color: var(--brand-peppercorn);
    background-color: var(--brand-parsnip);
    transition: background-color 0.2s ease-in-out 0s, opacity 0.2s ease-in-out 0s;

    &:visited {
        color: var(--brand-peppercorn);
    }

    &:hover {
        cursor: pointer;
        color: var(--brand-peppercorn);
        background-color: rgb(217, 215, 205); /* brand-parsnip darkened 10% */
    }

    &:focus {
        border: 1px solid var(--brand-kale);
        box-shadow: inset 0 0 0 1px var(--brand-kale);
        outline-width: 0;
    }

    &:active,
    &.active {
        background-color: rgb(206, 203, 191); /* brand-parsnip darkened 15% */
    }

    &[disabled],
    &.disabled,
    &.loading {
        pointer-events: none;
        border: 1px solid rgb(222, 221, 220); /* brand-peppercorn tinted 85% */
        color: rgb(189, 187, 185); /* brand-peppercorn tinted 70% */
        background-color: transparent;

        /* disabled buttons on a dark background */
        &.button--onDarkBg {
            opacity: 0.3;
        }
    }

    &.disabled.clickable {
        pointer-events: all;
    }

    font-size: var(--font-size-small);
    height: calc(var(--base-unit) * 9);
    line-height: calc(var(--base-unit) * 9 - 2px);

    &.clearMargin {
        margin: 0;
    }
}

:global(.mcds-redesign-molecules) .container {
    border-radius: var(--radius-action);
}

.important {
    color: var(--brand-coconut);
    background-color: rgb(113, 107, 103); /* peppercorn tinted 35% */
    -ms-transition:
        background-color 0.2s ease-in-out 0s,
        opacity 0.2s ease-in-out 0s;
    transition: background-color 0.2s ease-in-out 0s, opacity 0.2s ease-in-out 0s;

    &:visited {
        color: var(--brand-coconut);
    }

    &:hover {
        cursor: pointer;
        color: var(--brand-coconut);
        background-color: rgb(86, 82, 79); /* backround-color darkened by 10% */
    }

    &:focus {
        border: 1px solid rgb(0, 78, 86); /* brand-kale darkened by 10% */
        box-shadow: inset 0 0 0 1px rgb(0, 78, 86); /* brand-kale darkened by 10% */
        outline-width: 0;
    }

    &:active,
    &.active {
        background-color: rgb(73, 69, 67); /* background-color darkened by 15% */
    }

    &[disabled],
    &.disabled,
    &.loading {
        pointer-events: none;
        border: 1px solid rgb(222, 221, 220); /* brand-peppercorn tinted 85% */
        color: rgb(189, 187, 185); /* brand-peppercorn tinted 70% */
        background-color: transparent;

        /* disabled buttons on a dark background */
        &.button--onDarkBg {
            opacity: 0.3;
        }
    }

    &.disabled.clickable {
        pointer-events: all;
    }

    &:focus {
        border: 1px solid rgb(0, 78, 86); /* brand-kale darkened by 10% */
        box-shadow: inset 0 0 0 1px rgb(0, 78, 86); /* brand-kale darkened by 10% */
        box-shadow:
            inset 0 0 0 1px rgb(0, 78, 86),
            inset 0 0 0 2px var(--brand-coconut);
    }
}

.primary {
    color: var(--brand-coconut);
    background-color: var(--brand-kale);
    -ms-transition:
        background-color 0.2s ease-in-out 0s,
        opacity 0.2s ease-in-out 0s;
    transition: background-color 0.2s ease-in-out 0s, opacity 0.2s ease-in-out 0s;

    &:visited {
        color: var(--brand-coconut);
    }

    &:hover {
        cursor: pointer;
        color: var(--brand-coconut);
        background-color: rgb(0, 101, 112); /* brand-kale darkened by 5% */
    }

    &:focus {
        border: 1px solid rgb(0, 101, 112);
        box-shadow: inset 0 0 0 1px rgb(0, 101, 112);
        outline-width: 0;
    }

    &:active,
    &.active {
        background-color: rgb(0, 101, 112); /* brand-kale darkened by 10% */
    }

    &[disabled],
    &.disabled,
    &.loading {
        pointer-events: none;
        border: 1px solid rgb(222, 221, 220); /* brand-peppercorn tinted 85% */
        color: rgb(189, 187, 185); /* brand-peppercorn tinted 70% */
        background-color: transparent;

        /* disabled buttons on a dark background */
        &.button--onDarkBg {
            opacity: 0.3;
        }
    }

    &.disabled.clickable {
        pointer-events: all;
    }

    &:focus {
        border: 1px solid rgb(0, 101, 112);
        box-shadow: inset 0 0 0 1px rgb(0, 101, 112);
        box-shadow:
            inset 0 0 0 1px rgb(0, 101, 112),
            inset 0 0 0 2px var(--brand-coconut);
    }
}

.secondary {
    color: var(--brand-peppercorn);
    background-color: var(--brand-parsnip);
    transition: background-color 0.2s ease-in-out 0s, opacity 0.2s ease-in-out 0s;
    -ms-transition:
        background-color 0.2s ease-in-out 0s,
        opacity 0.2s ease-in-out 0s;

    &:visited {
        color: var(--brand-peppercorn);
    }

    &:hover {
        cursor: pointer;
        color: var(--brand-peppercorn);
        background-color: rgb(217, 215, 205); /* brand-parsnip darkened 10% */
    }

    &:focus {
        border: 1px solid var(--brand-kale);
        box-shadow: inset 0 0 0 1px var(--brand-kale);
        outline-width: 0;
    }

    &:active,
    &.active {
        background-color: rgb(206, 203, 191); /* brand-parsnip darkened 15% */
    }

    &[disabled],
    &.disabled,
    &.loading {
        pointer-events: none;
        border: 1px solid rgb(222, 221, 220); /* brand-peppercorn tinted 85% */
        color: rgb(189, 187, 185); /* brand-peppercorn tinted 70% */
        background-color: transparent;

        /* disabled buttons on a dark background */
        &.button--onDarkBg {
            opacity: 0.3;
        }
    }

    &.disabled.clickable {
        pointer-events: all;
    }

    &:focus {
        border: 1px solid var(--brand-kale);
        box-shadow: inset 0 0 0 1px var(--brand-kale);
        box-shadow:
            inset 0 0 0 1px var(--brand-kale),
            inset 0 0 0 2px var(--brand-coconut);
    }
}

/* Buttons that visually look like links */
.tertiary {
    color: var(--brand-kale);
    background-color: transparent;

    &:visited {
        color: var(--brand-kale);
    }

    &:hover {
        cursor: pointer;
        color: var(--brand-kale);
        background-color: transparent;
    }

    &:focus {
        border: 1px solid var(--brand-kale);
        box-shadow: inset 0 0 0 1px var(--brand-kale);
        outline-width: 0;
    }

    &:active,
    &.active {
        background-color: transparent;
    }

    &[disabled],
    &.disabled,
    &.loading {
        pointer-events: none;
        border: 1px solid rgb(222, 221, 220); /* brand-peppercorn tinted 85% */
        color: rgb(189, 187, 185); /* brand-peppercorn tinted 70% */
        background-color: transparent;

        /* disabled buttons on a dark background */
        &.button--onDarkBg {
            opacity: 0.3;
        }
    }

    &.disabled.clickable {
        pointer-events: all;
    }

    padding: 0;
    line-height: inherit;
    height: auto;
    margin-right: 0;
    text-transform: unset; /* For buttons that look like links, we don't need to adjust the casing */
    font-size: inherit;
    vertical-align: baseline;

    &:hover {
        color: var(--link-color-hover);
        text-decoration: underline;
    }

    &:focus {
        border-color: transparent;
        box-shadow: none;
        outline-width: thick;
    }

    &[disabled] {
        border: none;
    }
}

.dropdownTrigger {
    color: var(--brand-peppercorn);
    background-color: var(--brand-coconut);
    -ms-transition:
        background-color 0.2s ease-in-out 0s,
        opacity 0.2s ease-in-out 0s;
    transition: background-color 0.2s ease-in-out 0s, opacity 0.2s ease-in-out 0s;

    &:visited {
        color: var(--brand-peppercorn);
    }

    &:hover {
        cursor: pointer;
        color: var(--brand-peppercorn);
        background-color: var(--brand-coconut);
    }

    &:focus {
        border: 1px solid var(--brand-kale);
        box-shadow: inset 0 0 0 1px var(--brand-kale);
        outline-width: 0;
    }

    &:active,
    &.active {
        background-color: var(--brand-coconut);
    }

    &[disabled],
    &.disabled,
    &.loading {
        pointer-events: none;
        border: 1px solid rgb(222, 221, 220); /* brand-peppercorn tinted 85% */
        color: rgb(189, 187, 185); /* brand-peppercorn tinted 70% */
        background-color: transparent;

        /* disabled buttons on a dark background */
        &.button--onDarkBg {
            opacity: 0.3;
        }
    }

    &.disabled.clickable {
        pointer-events: all;
    }

    font-weight: 400;
    border: 1px solid var(--brand-peppercorn-30);
}

:global(.project-runway) {
    .dropdownTrigger {
        color: var(--text-primary-color);
        background-color: var(--bg-lvl-1-color);
        border: 1px solid var(--border-color);
    }
}

.normalWeight {
    font-weight: normal;
}

.loading {
    background-size: 20px;
    background-position: 18px center;
    background-repeat: no-repeat;
    padding-left: 44px;
    background-image: url('https://cdn-images.mailchimp.com/product/buttons/loader-dark@2x.png');

    &[disabled] {
        background-image: url('https://cdn-images.mailchimp.com/product/buttons/loader-light@2x.png');
    }
}

:global(.project-runway) {
    .container {
        &:hover {
            background-color: var(--bg-lvl-2-color);
        }

        &:active,
        &.active {
            background-color: var(--bg-lvl-2-color);
        }

        &[disabled],
        &.disabled,
        &.loading {
            border: 1px solid var(--border-light-color);
            color: var(--text-secondary-color);
        }
    }

    .important {
        color: var(--interactive-color);
        background-color: transparent;
        border: 1px solid var(--interactive-color);

        &:hover {
            background-color: var(--bg-interactive-color);
        }

        &:focus {
            border: 1px solid var(--border-interactive-color);
            box-shadow: inset 0 0 0 1px var(--interactive-hover-color);
            box-shadow:
                inset 0 0 0 1px var(--interactive-hover-color),
                inset 0 0 0 2px var(--brand-coconut);
        }

        &:active,
        &.active {
            background-color: var(--bg-lvl-4-color);
        }

        &[disabled],
        &.disabled,
        &.loading {
            border: 1px solid var(--border-light-color);
            color: var(--text-secondary-color);
        }
    }

    .primary {
        &:hover {
            background-color: var(--interactive-hover-color);
        }

        &:focus {
            border: 1px solid var(--border-interactive-color);
            box-shadow: inset 0 0 0 1px var(--interactive-hover-color);
            box-shadow:
                inset 0 0 0 1px var(--interactive-hover-color),
                inset 0 0 0 2px var(--brand-coconut);
        }

        &:active,
        &.active {
            background-color: var(--interactive-hover-color);
        }

        &[disabled],
        &.disabled,
        &.loading {
            pointer-events: none;
            border: 1px solid var(--border-light-color);
            color: var(--text-secondary-color);
        }
    }

    .secondary {
        &:hover {
            background-color: var(--border-color);
        }

        &:active,
        &.active {
            background-color: var(--border-color);
        }

        &[disabled],
        &.disabled,
        &.loading {
            border: 1px solid var(--border-light-color);
            color: var(--text-secondary-color);
        }
    }

    .tertiary {
        &[disabled],
        &.disabled,
        &.loading {
            pointer-events: none;
            border: 1px solid var(--border-light-color);
            color: var(--text-secondary-color);
        }
    }

    .dropdownTrigger {
        &[disabled],
        &.disabled,
        &.loading {
            pointer-events: none;
            border: 1px solid var(--border-light-color);
            color: var(--text-secondary-color);
        }
    }
}
