.messageContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: var(--hosted-survey-message-minimum-height);
    padding: var(--spacing-7);
    white-space: pre-wrap;
}

.messageBody {
    max-width: var(--hosted-survey-message-width);
    text-align: center;
}
