.fieldWrapper {
    margin: 0;
    margin-bottom: var(--spacing-6);
    clear: both;
    position: relative;
    overflow: visible;
}

.invalidError {
    white-space: normal;
    word-wrap: break-word;
    width: 100%;
    max-width: 635px;
    font-size: 1.4rem;
    line-height: 18px;
    font-weight: 500;
    position: relative;
    margin-top: var(--spacing-3);
    margin-bottom: 18px;
    max-height: 200px;
    color: #a73205;
}

.description {
    white-space: normal;
    word-wrap: break-word;
    width: 100%;
    max-width: 635px;
    font-size: 1.4rem;
    line-height: 18px;
    font-weight: 500;
    position: relative;
    color: var(--legacy-text-secondary-color);
    margin-top: var(--spacing-3);
    margin-bottom: 18px;
}
